import React from 'react';
import BlackHoleSimulation from './BlackHoleSimulation';

const App = () => {


  return (
    <BlackHoleSimulation />
  );
};

export default App;
