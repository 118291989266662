import React, { useRef, useEffect, useState } from 'react';

const BlackHoleSimulation = () => {
    const canvasRef = useRef(null);
    const [is3D, setIs3D] = useState(false);
    const [transitioning, setTransitioning] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        let width, height, cx, cy, blackHoleRadius, particles = [];
        let animationFrameId;
        let rotationAngleX = 0;
        let rotationAngleY = 0;
        let transitionProgress = 1;
        let sizeFactor = 1;
        let sizeDirection = 1;

        const initSimulation = () => {
            width = window.innerWidth;
            height = window.innerHeight;
            canvas.width = width;
            canvas.height = height;

            cx = width / 2;
            cy = height / 2;

            blackHoleRadius = Math.min(width, height) * 0.1;

            const numParticles = Math.floor((width * height) / 500);
            particles = [];
            for (let i = 0; i < numParticles; i++) {
                const orbitRadius = Math.random() * Math.max(width, height) * 0.6;
                const angle = Math.random() * Math.PI * 2;
                const inclination = (Math.random() - 0.5) * Math.PI;
                const speed = 0.02 * (blackHoleRadius / (orbitRadius + blackHoleRadius));
                const size = 1 + Math.random() * 3;
                const colorShift = i * 10;
                const decay = Math.random() * 0.002 + 0.0005;
                const fadeInDelay = i * 50; // Delay for fade-in effect
                particles.push({ orbitRadius, angle, inclination, speed, size, colorShift, decay, fadeInDelay, opacity: 0 });
            }
        };

        initSimulation();

        const animate = () => {
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = "#000";
            ctx.fillRect(0, 0, width, height);

            if (is3D) {
                rotationAngleX += 0.002;
                rotationAngleY += 0.001;
                sizeFactor += sizeDirection * 0.02;
                if (sizeFactor >= 10) sizeDirection = -1;
                if (sizeFactor <= 1) sizeDirection = 1;
            } else {
                sizeFactor = 1;
            }

            if (transitioning) {
                transitionProgress = Math.max(0, transitionProgress - 0.02);
                if (transitionProgress === 0) {
                    setTransitioning(false);
                }
            }

            const getColor = (shift) => {
                const time = (Date.now() + shift) % 3000;
                const phase = Math.floor(time / 1000) % 3;
                let r = 0, g = 0, b = 0;

                if (phase === 0) {
                    r = 255 * (time % 1000) / 1000;
                    g = 255 - r;
                } else if (phase === 1) {
                    g = 255 * (time % 1000) / 1000;
                    b = 255 - g;
                } else {
                    b = 255 * (time % 1000) / 1000;
                    r = 255 - b;
                }
                return `rgb(${Math.floor(r)}, ${Math.floor(g)}, ${Math.floor(b)})`;
            };

            particles.forEach(p => {
                p.angle += p.speed;
                p.orbitRadius -= p.decay;
                if (p.orbitRadius < blackHoleRadius) {
                    p.orbitRadius = Math.random() * Math.max(width, height) * 0.6 + blackHoleRadius;
                    p.angle = Math.random() * Math.PI * 2;
                    p.inclination = (Math.random() - 0.5) * Math.PI;
                }

                let x, y;
                if (is3D || transitioning) {
                    const progressFactor = is3D ? 1 : transitionProgress;
                    const rotatedX = p.orbitRadius * Math.cos(p.angle + rotationAngleX) * Math.cos(rotationAngleY) * progressFactor;
                    const rotatedY = p.orbitRadius * Math.sin(p.angle + rotationAngleX) * Math.cos(p.inclination) * progressFactor;
                    const rotatedZ = p.orbitRadius * Math.sin(rotationAngleY) * progressFactor;
                    const perspectiveScale = 1 + rotatedZ * 0.5;
                    x = cx + rotatedX * perspectiveScale;
                    y = cy + rotatedY * perspectiveScale;
                } else {
                    x = cx + p.orbitRadius * Math.cos(p.angle);
                    y = cy + p.orbitRadius * Math.sin(p.angle);
                }

                if (!is3D && Date.now() > p.fadeInDelay) {
                    p.opacity = Math.min(1, p.opacity + 0.02); // Gradually increase opacity
                } else if (is3D) {
                    p.opacity = 1; // Set opacity to 1 in 3D mode
                }

                ctx.beginPath();
                ctx.arc(x, y, p.size * sizeFactor, 0, Math.PI * 2);
                ctx.fillStyle = getColor(p.colorShift);
                ctx.globalAlpha = p.opacity; // Apply opacity
                ctx.fill();
                ctx.globalAlpha = 1; // Reset opacity
            });

            ctx.beginPath();
            ctx.arc(cx, cy, blackHoleRadius, 0, Math.PI * 2);
            ctx.fillStyle = "#000";
            ctx.fill();

            animationFrameId = requestAnimationFrame(animate);
        };

        animate();

        const handleResize = () => {
            initSimulation();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationFrameId);
        };
    }, [is3D]);

    const toggleMode = (event) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const distance = Math.sqrt((x - rect.width / 2) ** 2 + (y - rect.height / 2) ** 2);

        if (distance <= rect.width * 0.1) {
            if (is3D) {
                setIs3D(false);
            } else {
                setIs3D(true);
            }
        }
    };

    return (
        <canvas
            ref={canvasRef}
            style={{ display: 'block', width: '100vw', height: '100vh' }}
            onClick={toggleMode}
        />
    );
};

export default BlackHoleSimulation;
